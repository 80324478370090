import React from 'react'

export default function VideoPlay({ videoUrl, handleCloseVideo }) {
  const handleOnCloseVideo = () => {
    handleCloseVideo(false)
  }
  return (
    <div
      className="top-0 opacity-100 overflow-hidden w-screen h-screen fixed bg-shark-500 bg-opacity-80 left-0"
      style={{ transition: "opacity 0.5s ease-out", zIndex: 2000 }}
    >
      <div
        className="w-full lg:w-3/4 h-full mx-auto my-auto relative"
        style={{
          padding: "0 1.6667rem",
          transition: "all 0.5s ease-out",
        }}
      >
        <div className="absolute left-1/2 top-1/2	w-full translate-50 px-3">
          <div
            id="video_close_btn"
            className="video_close_btn absolute right-0 opacity-100 cursor-pointer px-3"
            aria-hidden="true"
            onClick={handleOnCloseVideo}
            style={{
              top: "-40px",
              zIndex: 500,
              fontFamily: "saileclight",
              fontSize: "45px",
              lineHeight: "25px",
              color: "#ffffff",
              transform: "rotate(45deg)",
            }}
          >
            +
          </div>
          <div
            className="relative w-full"
            style={{ paddingBottom: "56.25%" }}
          >
            <iframe
              className="absolute top-0 left-0 h-full w-full"
              title="myFrame"
              src={`${videoUrl}?autoplay=1`}
              frameBorder="1"
              allow="accelerometer; autoplay; clipboardWrite; encryptedMedia; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
      </div>
    </div>
  )
}
